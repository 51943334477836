<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <a-button
          v-if="isShowBtns.indexOf('ConstructType-/api/projectType/add') > -1"
          type="primary"
          @click="showDrawer('ConstructTypeEdit', '新增', 'add', record)"
          >新增</a-button
        >
      </div>
      <div>
        <!-- <a-button type="plain" icon="cloud-download" @click="handleExport"
          >导出</a-button
        > -->
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="ConstructType"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="ptId"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 800, y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
    >
      <!--  @change="changeTable" -->
      <span slot="tbranchcompany" slot-scope="text">
        <div>{{ text.bcName }}</div>
      </span>
      <span slot="createTime" slot-scope="text, record">
        <div v-if="record.createTime == ''">{{ "-" }}</div>
        <div>{{ $Format(record.createTime).slice(0, 10) }}</div>
      </span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a
          class="editBtn"
          v-if="
            isShowBtns.indexOf('ConstructType-/api/projectType/update') > -1
          "
          @click="showDrawer('ConstructTypeEdit', '修改', 'edit', record)"
          >修改</a
        >
        <a
          class="editBtn"
          v-if="isShowBtns.indexOf('ConstructType-/api/projectType/add') > -1"
          type="primary"
          @click="showDrawer('ConstructTypeEdit', '新增', 'add', record)"
          >新增</a
        >
        <a-popconfirm
          v-if="
            isShowBtns.indexOf('ConstructType-/api/projectType/delete') > -1
          "
          title="确定删除此条施工项类型信息吗?"
          @confirm="() => onDelete(record)"
        >
          <a class="delName">删除</a>
        </a-popconfirm>
      </span>
    </s-table>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="750"
      :destroyOnClose="destroyOnClose"
    >
      <ConstructTypeEdit
        v-if="ConstructTypeEdit"
        :record="record"
        :type="type"
        @onClose="onClose"
      />
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetProjectTypeList, DeleteProjectType } from "@/api/apiJF/template";
import { GetCompanies } from "@/api/device";
import ConstructTypeEdit from "./ConstructTypeEdit.vue";

// 搜索条件
const queryItems = [
  {
    label: "施工项类型",
    value: "ptName",
    type: "input",
  },
  {
    label: "公司名称",
    value: "ptBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
  },
];
const columns = [
  {
    title: "类型名称",
    // width: "200px",
    scopedSlots: { customRender: "ptName" },
    dataIndex: "ptName",
    // align: "center",
  },

  // {
  //   title: "品牌描述",
  //   width: "300px",
  //   scopedSlots: { customRender: "mbDesc" },
  //   dataIndex: "mbDesc",
  //   align: "center",
  // },
  {
    title: "公司名称",
    width: "250px",
    scopedSlots: { customRender: "tbranchcompany" },
    dataIndex: "tbranchcompany",
    align: "center",
  },
  {
    title: "创建时间",
    width: "200px",
    scopedSlots: { customRender: "createTime" },
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "操作",
    width: "160px",
    scopedSlots: { customRender: "action" },
    // fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];

export default {
  name: "ConstructType",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    ConstructTypeEdit,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {
        ptName: "",
        ptBcIds: "24",
        pageNo: 1,
        pageSize: 10,
      },
      destroyOnClose: true,
      isShowBtns: [],
      visible: false,
      DrawerTitle: "",
      ConstructTypeEdit: false,
      record: {
        ptBcIds: "",
      },
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      SSearchShow: true,
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // console.log(CompaniesResult(24));
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanyList.length = 0;

          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          this.queryParam.ptBcIds = this.CompanyListid.toString();
          this.record.ptBcIds = this.CompanyListid.toString();
          this.$refs.table.refresh(true);
          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("ConstructTypeColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      let newObject = {};
      for (let el in this.queryParam) {
        if (this.queryParam[el] != "") {
          newObject[el] = this.queryParam[el];
        }
      }
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectTypeList(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;

        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      if (this.queryParam.ptBcIds == "") {
        this.queryParam.ptBcIds = this.CompanyListid.toString();
      }
      this.$refs.table.refresh(true);
    },
    // changeTable(pagination, filters, sorter, { currentDataSource }) {
    //   // console.log(pagination, filters, sorter, { currentDataSource });
    // },
    handleExport() {
      this.confirmLoading = false;
      this.visible = true;
    },
    // 删除类别信息
    onDelete(record) {
      // console.log("record", record);
      const param = {
        ptId: record.ptId,
      };
      const orderParam = ["ptId"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return DeleteProjectType(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("删除成功");
        } else {
          this.$message.error(res.msg);
        }
        this.$refs.table.refresh(true);
      });
    },
    // 弹窗
    afterVisibleChange(val) {
      // console.log("visible", val);
    },
    showDrawer(name, title, type, record) {
      this.DrawerTitle = title;
      this.visible = true;
      this[name] = true;
      this.record = record;
      this.record.ptBcIds = this.CompanyListid.toString();

      this.type = type;
    },
    onClose() {
      this.visible = false;
      this.ConstructTypeEdit = false;
      this.$refs.table.refresh(true);
      this.record = {};
    },
  },
};
</script>
<style lang="less" scoped>
.delName {
  color: red;
}
.editBtn {
  margin-right: 20px;
}
.table-button-flex {
  display: flex;
  justify-content: space-between;
}
</style>